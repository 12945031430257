import React, { useEffect, useState } from "react";
import classNames from "../../classNames";
import compressImage from "../../compressImage";
import SaveFileUseCase from "../../usecases/file/SaveFileUseCase";
import SaveImageUseCase from "../../usecases/file/SaveImageUseCase";

import Spinner from "../Spinner";

const defaultProps = {
  object: {},
};

function InputFile2({ className, field, object, ...props }) {
  
  const [collapse, setCollapse] = useState(false);
  const [filesLength, setFilesLength] = useState(object[field]?.length || null);
  const [loading, setLoading] = useState(false)

  const [progress, setProgress] = useState(0)

  const classes = classNames("form-control", className);
  const value = object[field];

  const onCollapse = () => {
    return (
      <div>
        {object?.field?.map((pic) => (
          <img
            src={pic}
            alt=""
            style={{ width: "50px", height: "50px" }}
            className="m-3 rounded-circle"
          />
        ))}
      </div>
    );
  };

  async function saveFiles(files, object, field) {

    const options = {
      progress: value => {
        if (value === 1){
          setProgress((progress) => progress + 1)
        }
      },
      maxWidth: 300,
      maxHeight: 300,
    }

    let images = [];

    for (const file of files) {
      try {
        const result = await new SaveImageUseCase().execute(file, options);
        images.push(result.url);
        object[field] = images;
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  function onChange(e) {

    setLoading(true)

    const files = Array.prototype.slice.call(e.target.files);
    const i = 0

    setFilesLength(files.length)
    setProgress(0)

    if (files?.length > 0) {
      saveFiles(files, object, field)
    }
  }

  return (
    <>
    <input
      type="file"
      accept="image/*"
      className={classes}
      name={field}
      onChange={onChange}
      multiple
      {...props}
    />
    <div className="grid ">
      <div className="row align-items-center">
        <div className="col-auto">
          <button className={"btn btn-primary mb-2 mt-2"} type="button"
          onClick={() => onCollapse(setCollapse(!collapse))}>
          {<>View Image&nbsp;&nbsp;&nbsp;&nbsp; {collapse === true ? "⮟" : "⮞"}</>} 
          </button>
        </div>
        <div className={loading ? "col": 'd-none'}>
          <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar" style={{width: `${(((progress/2)/filesLength)*100)}%` }}></div>
          </div>
        </div>


      </div>
    </div>
      {collapse === true ? (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 8,
          }}
        >
          {object[field] && object[field].map((pic) => (
            <img
              src={pic}
              alt=""
              style={{ width: "50px", height: "50px" }}
              className="m-3 rounded-circle"
            />
          ))}
        </div>
      ) : null}
    </>
  );
}

InputFile2.defaultProps = defaultProps;

export default InputFile2;
