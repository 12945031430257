import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  options: [], //it can be list of string or object with label and value
  indexes: ["name"], // list of key of object you want to display
  onClickItem: noop,
};

function SelectList({
  className,
  options,
  indexes,
  onClickItem,
  onClickCancel,
  ...props
}) {
  return (
    <ul className={classNames("list-group mb-3", className)} {...props}>
      {options.map((o, i) => (
        <li
          onClick={onClickItem.bind(this, o, i)}
          className="list-group-item cursor-pointer"
        >
          {indexes.map((index) => o[index] || o.id || o).join(" ")}
        </li>
      ))}
      <div className="d-flex justify-content-end mt-2">
        <button onClick={onClickCancel.bind(this)} className="btn btn-primary">
          Close
        </button>
      </div>
    </ul>
  );
}

SelectList.defaultProps = defaultProps;

export default SelectList;
