import React from "react";
import BasePage from "../../../base/BasePage";
import NavBar from "../../../components/NavBar";
import withContext from "../../../withContext";
import { Button } from "nq-component";

import CreateNewTicketPresenter from "./CreateNewTicketPresenter";
import { findObjectUseCase, saveObjectUseCase } from "../../../domain/object";
import { InputSelect, InputText } from "nq-component";
import InputFactory from "../../../components/InputFactory";
import { saveFileUseCase } from "../../../domain/file";
import InputFile from "../../../components/InputFile";
import schemas from "../../../schemas.json";

class CreateNewTicketPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = { object: { status: "open" } };
    this.presenter = new CreateNewTicketPresenter(
      this,
      saveObjectUseCase(),
      saveFileUseCase()
    );
  }

  getObject() {
    return this.state.object;
  }

  sendTicket(e) {
    e.preventDefault();
    console.log(this.state);
    this.presenter.saveNewTicket();
    // this.presenter.sendTicket();
  }

  onCategoryChange(e) {
    console.log(e);
  }

  goBack(e) {
    this.presenter.goBack();
  }

  render() {
    const object = this.state.object;

    return (
      <>
        <NavBar className="shadow-sm" />
        <form onSubmit={this.sendTicket.bind(this)}>
          <div className="container px-lg-4 py-lg-3 overflow-auto">
            <div className="container px-lg-4 py-lg-3 ">
              <h2 className="m-0 pe-5">Create New Ticket</h2>
              <hr className="m-0 w-100" />

              <div className="container bg-white p-3 w-50">
                <h4 className="">Please fill up the ff.</h4>
                Recieve & resolve issues,requests,faults & incidents
                <InputFactory
                  type="Pointer"
                  required
                  target="departments"
                  field="department"
                  schemas={schemas}
                  name="Department"
                  object={object}
                  find={findObjectUseCase()}
                />
                <InputFactory
                  type="Pointer"
                  required
                  target="ticket_categories"
                  field="category"
                  schemas={schemas}
                  name="Category"
                  object={object}
                  find={findObjectUseCase()}
                />
                <div className="form-floating">
                  <InputSelect
                    required
                    className="form-select"
                    options={["Low", "Medium", "High"]}
                    field="priority"
                    object={object}
                  >
                    <label htmlFor="floatingSelect">Severity</label>
                  </InputSelect>
                </div>
                <div className="mb-1">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  ></label>
                  <InputText
                    required
                    className="form-control"
                    placeholder="Descrive your problem"
                    field="content"
                    object={object}
                    rows="2"
                  ></InputText>
                </div>
                <div className="mb-2">
                  <label htmlFor="formFileSm" className="form-label">
                    Attachment
                  </label>
                  <InputFile
                    className="form-control"
                    field="attachment"
                    object={object}
                    required
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={this.goBack.bind(this)}
                    type="button"
                    className="btn btn-light border"
                  >
                    Go back
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    Send Ticket
                    <i className="bi bi-send"></i>
                  </Button>
                </div>
              </div>
            </div>

            <main className="vh-100 d-flex flex-column"></main>
          </div>
        </form>
      </>
    );
  }
}

export default withContext(CreateNewTicketPage);
