import answersScore from "./answersScore";
import dateFormat from "./dateFormat";

function StoreRanking(answers) {
  const score = answers.reduce((acc, cur) => {
    const store = cur.store;
    const form = cur.form;
    const answers = cur.answers;
    const brand = cur.brand;

    // const date = cur.createdAt;
    // every store id has own object property
    const initial = {
      id: store.id,
      score: 0,
      total: 0,
      average: 0,
      name: store.name,
      form: {},
      data: 0,
      brand: brand
      // createdAt: dateFormat(date),
    };
    acc[store.id] = acc[store.id] || initial;
    const result = answersScore(answers);
    acc[store.id].score += result.score;
    acc[store.id].total += result.total;
    // // add result per form
    acc[store.id].form[form.name] = acc[store.id].form[form.name] || {};
    acc[store.id].form[form.name].id = form.id;
    acc[store.id].form[form.name].score =
      (acc[store.id].form[form.name].score || 0) + result.score;
    acc[store.id].form[form.name].total =
      (acc[store.id].form[form.name].total || 0) + result.total;
    return acc;
  }, {});
  // ran average
  Object.values(score).forEach((object) => {
    object.average = (object.score / object.total) * 100;
  });
  // rank by average
  const rank = Object.values(score).sort((a, b) =>
    a.average < b.average ? 1 : -1
  );
  return rank;
}

export default StoreRanking;
