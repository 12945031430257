
import React from 'react';

const defaultProps = {
    object: {},
    type: 'text',
};

function InputString({className, field, options, object, shouldValidate, setState, ...props}) {
    const [value, setValue] = React.useState(object[field] || '');
    const [isEmpty, setIsEmpty] = React.useState(false)

    React.useEffect(() => {
        if(shouldValidate){
            if(value === ''){
                setIsEmpty(true)    
            }else {
                setIsEmpty(false)
            }
        }
    }, [shouldValidate])

    React.useEffect(() => {
        setValue(object[field] || '');
    }, [object])

    function onInput(e) {
        const _value = e.target.value;
        setValue(_value);
        object[field] = _value;
       if(shouldValidate){
            if(e.target.value === '') {
                setIsEmpty(true)
            }else {
                setIsEmpty(false)
            }
       }
    }

    function onChange(e){
        if(setState){
            setState(e.target.value)
           }
    }



    return (
        <input
            value={value}
            className= {isEmpty ? 'border border-danger form-control' : 'form-control'}
            name={field}
            onInput={onInput}
            onChange={onChange}

            {...props}/>
    )
}

InputString.defaultProps = defaultProps;
export default InputString;
