class CreateNewTicketPresenter {
  constructor(view, saveObjectUseCase, saveFileUseCase) {
    this.view = view;
    this.saveObjectUseCase = saveObjectUseCase;
    this.saveFileUseCase = saveFileUseCase;
  }

  saveNewTicket() {
    this.saveTicket();
  }

  saveTicket() {
    const object = this.view.getObject();
    const user = this.view.getCurrentUser();
    const ticket = {
      department: object.department,
      creator: user,
      category: object.category,
      priority: object.priority,
      status: object.status,
    };

    console.log(ticket);
    this.saveObjectUseCase
      .execute("tickets", ticket)
      .then((ticket) => {
        const ticketComments = {
          ticket: ticket,
          content: object.content,
          sender: user,
          attachment: object.attachment,
        };
        this.saveObjectUseCase
          .execute("ticket_comments", ticketComments)
          .then(() => {
            this.view.showSuccess("Ticket Submitted");
            this.view.navigateTo("/support");
          })
          .catch((error) => {
            this.view.showError(error);
          });
      })
      .catch((error) => {
        this.view.showError(error);
      });
  }

  sendTicket() {
    this.view.showSuccess("Ticket created successfully");
    this.view.navigateTo("/support");
  }

  goBack() {
    this.view.navigateTo("/support");
  }
}

export default CreateNewTicketPresenter;
