import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import { findObjectUseCase } from "../../domain/object";
import DashboardPresenter from "./DashboardPresenter";

class DashboardPage extends BasePage {
  constructor() {
    super();
    this.presenter = new DashboardPresenter(this, findObjectUseCase());
    this.state = {
      learns: [],
      supports: [],
      announcements: [],
      countLearn: 0,
      countSupport: 0,
      countAnnouncement: 0,
      progress: false,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setLearns(learns) {
    this.setState({
      learns,
    });
  }

  setSupports(supports) {
    this.setState({
      supports,
    });
  }

  setAnnouncements(announcements) {
    this.setState({
      announcements,
    });
  }

  setCountLearn(countLearn) {
    return this.setState({ countLearn });
  }

  setCountSupport(countSupport) {
    return this.setState({ countSupport });
  }

  setCountAnnouncement(countAnnouncement) {
    return this.setState({ countAnnouncement });
  }

  render() {
    const learn = this.state.countLearn;
    const support = this.state.countSupport;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar className="shadow-sm" />
        <main className="container">
          <div className="py-1 px-lg-5 py-lg-1">
            <div className="d-flex align-items-center p-3 my-3 text-white bg-primary rounded shadow-sm">
              <div className="lh-1">
                <h1 className="h6 mb-0 text-white lh-1">Welcome {user.name}</h1>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-6">
                <Card className="border-start border-5 border-primary">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <i className="bi bi-folder me-1" />0
                      <Link to="/form">
                        <p className="m-0">Forms to fill</p>
                      </Link>
                    </div>
                    <i className="bi bi-chevron-right" />
                  </div>
                </Card>
              </div>
              <div className="col-6">
                <Link to="/train">
                  <Card className="border-start border-5 border-success">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <i className="bi bi-file-play  me-1" />
                        {learn}

                        <p className="m-0">Learn</p>
                      </div>
                      <i className="bi bi-chevron-right" />
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-6">
                <Link to="/support">
                  <Card className="border-start border-5 border-danger">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <i className="bi bi-exclamation-triangle me-1" />
                        {support}

                        <p className="m-0">Tickets</p>
                      </div>
                      <i className="bi bi-chevron-right" />
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-6">
                <Card className="border-start border-5 border-secondary">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <i className="bi bi-folder me-1" />0
                      <p className="m-0">Memorandum</p>
                    </div>
                    <i className="bi bi-chevron-right" />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default DashboardPage;
