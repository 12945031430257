import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import Card from "../../components/Card";
import {InputString} from "nq-component";
import { Link } from "react-router-dom";
import SupportPresenter from "./SupportPresenter";
import { findObjectUseCase } from "../../domain/object";
import Img1 from "../../assets/img/undraw_solution_mindset_re_57bf.svg";
import withContext from "../../withContext";
import {Progress} from "nq-component";
import React from "react";
import {InfiniteScroll as Scroll} from "nq-component";
import dateFormat from "../../dateFormat";
import getDateAndTimeFromISO from "../../getDateAndTimeFromISO";

class SupportPage extends BasePage {
  constructor() {
    super();
    this.presenter = new SupportPresenter(this, findObjectUseCase());
    this.state = {
      tickets: [],
      progress: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setTickets(tickets) {
    this.setState({
      tickets,
    });
  }

  onClickOpened() {
    this.presenter.onClickOpened();
  }

  onCLickClosed() {
    this.presenter.onCLickClosed();
  }

  onClickTicket(ticket) {
    this.presenter.onClickTicket(ticket);
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  onSearchChange(e) {
    this.presenter.onSubmitSearch(e.target.value);
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const tickets = this.state.tickets;
    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="nav-scroller">
          <nav className="nav nav-tabs">
            <button
              onClick={this.onClickOpened.bind(this)}
              className="text-nowrap nav-link"
            >
              <i className="bi bi-bookmark-check"></i> My Tickets
            </button>
            <button
              onClick={this.onCLickClosed.bind(this)}
              className="text-nowrap nav-link"
            >
              <i className="bi bi-bookmark-check"></i> Closed
            </button>
          </nav>
        </div>
        <div className="container">
          <div className="py-2 px-lg-5 py-lg-4">
            {tickets.length < 0 ? (
              <div class="d-flex align-items-center justify-content-center">
                <div class="text-center mb-5 mt-5">
                  <h2 class="display-10 fw-bold ">Create your first ticket</h2>
                  <p>Receive & resolve issues, requests, faults & incidents</p>
                  <img
                    class="img-fluid login-img"
                    width="400"
                    src={Img1}
                    alt="banner"
                  />
                </div>
              </div>
            ) : (
              <Card className="mb-2 ">
                <div className="input-group">
                  <input
                    onChange={this.onSearchChange.bind(this)}
                    name="search"
                    type="search"
                    className="form-control"
                    placeholder="Search Ticket.."
                  />
                  <button className="btn btn-primary" type="button">
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </Card>
            )}
            <Scroll
              hasMore={tickets.length < count}
              loadMore={this.loadMore.bind(this)}
            >
              {tickets.map((ticket, id) => (
                <Card
                  className="mb-2 border-start border-5 border-primary cursor-pointer"
                  key={id}
                  onClick={this.onClickTicket.bind(this, ticket)}
                >
                  <div className="d-flex justify-content-between">
                    <p className="text-red-dark m-1">
                      <i className="bi bi-exclamation-triangle"></i> Ticket ID: {ticket.id.toUpperCase()}
                    </p>
                    {ticket.status === 'open' ? (
                        <i className="bi bi-circle-fill text-success float-end"> Open</i>
                    )
                    : (
                        <>
                          <i className="bi bi-circle-fill text-muted float-end"> Closed at: {getDateAndTimeFromISO(ticket.date_closed)}
                          </i>
                        </>
                    )
                    }
                  </div>

                  <div className="d-flex justify-content-between">
                    <strong className="text-red-dark m-1">
                      {ticket.category && ticket.category.name.toUpperCase()}
                    </strong>
                  </div>
                  {/*<small className="d-block">{ticket.name}</small>*/}

                  <small className="d-block">{(ticket.department.name).toUpperCase()}</small>
                  <small>Sender: {ticket.creator && ticket.creator.name}</small>
                  <small className="d-block float-end mt-2">
                    Created at: {getDateAndTimeFromISO(ticket.createdAt)}
                  </small>
                  <div className="d-flex text-muted pt-2">
                    {ticket.priority.toLowerCase() == "low" ? (
                      <span className="badge bg-success">
                        {ticket.priority}
                      </span>
                    ) : ticket.priority.toLowerCase() == "medium" ? (
                      <span className="badge bg-warning">
                        {ticket.priority}
                      </span>
                    ) : (
                      <span className="badge bg-danger">{ticket.priority}</span>
                    )}
                  </div>
                </Card>
              ))}
              {progress && <Progress className="fs-sm">Loading ...</Progress>}
              {!progress && tickets.length === 0 && <h3>No Data Found</h3>}
            </Scroll>
          </div>
        </div>
        <div className="position-fixed bottom-0 end-0">
          <Link to="/support/create">
            <button className="btn btn-primary rounded-circle m-4 shadow">
              <i className="bi bi-plus fs-4"></i>
            </button>
          </Link>
        </div>
      </>
    );
  }
}

export default withContext(SupportPage);
