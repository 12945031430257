import InputFactory from "../InputFactory";
import InputAnswerCheck from "../InputAnswerCheck";
import React, { useState, useEffect } from "react";
import InputString from "../InputString";
import InputText from "../InputText";

function InputAnswer({
  question,
  object,
  validate,
  submit,
  questionRef,
  setQuestionRef,
}) {
  const [answer, setAnswer] = React.useState(object["answer"]);
  const [remarks, setRemarks] = React.useState(object["remarks"]);
  const warningRef = React.useRef(null);

  useEffect(() => {
    if (submit && !answer) {
      addItemToQuestionRef(warningRef.current);
    } else if (submit && answer && answer === "NO" && !object["remarks"]) {
      addItemToQuestionRef(warningRef.current);
    }
  }, [submit]);

  const addItemToQuestionRef = (newItem) => {
    setQuestionRef((prevQuestionRef) => [...prevQuestionRef, newItem]);
  };

  return (
    <div>
      <p tabindex={0} ref={warningRef} className="m-0">
        {question}
      </p>
      <p
        className={
          (validate && !answer) ||
          (answer && answer === "NO" && !object["remarks"])
            ? "text-danger small"
            : "d-none"
        }
      >
        {validate && !answer
          ? "please check a box to proceed"
          : validate && answer && answer === "NO" && !remarks
          ? "The remarks field is required"
          : ""}
      </p>
      <InputAnswerCheck
        className="p-3 px-lg-5 py-lg-4 d-flex flex-column"
        field="answer"
        object={object}
        setAnswer={setAnswer}
        answer={answer}
      />
      {object.answer === "NO" ? (
        <div className="row">
          <div className="col my-auto">
            <InputText
              type="String"
              field="remarks"
              object={object}
              placeHolder="remarks"
              shouldValidate={validate}
              setState={setRemarks}
            />
  
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default InputAnswer;
