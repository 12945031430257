import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

const WelcomePage = () => {
  return (
    <main>
      <div class="vh-100">
        <div class="container d-flex align-items-center h-100 p-3">
          <div class="m-auto bg-white shadow rounded p-3">
            <div class="row">
              <div class="col-md-6 border-end border-1">
                <div class="h-100 d-flex align-items-center">
                  <div class="text-center p-3 w-100">
                    <img
                      class="img-fluid login-img mb-auto mt-3 p-2 d-flex"
                      width="200"
                      src={Logo}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-4 p-lg-5">
                <h1 class="fw-bold mb-1">Survey</h1>
                <h2 class="fw-bold mb-1 text-success">templates</h2>
                <h2 class="fw-bold mb-5">for bussiness</h2>

                <div class="text-center mb-1">
                  <Link to="/checklist" class="btn-primary w-100 btn">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default WelcomePage;
