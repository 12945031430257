import classNames from "../../classNames";
import React from "react";

const defaultProps = {
    object: {},
    type: 'text',
};

function InputText({className, field, options, object, setState, ...props}) {
    function onChange(e) {
        const value = e.target.value;
        object[field] = value;
        if(setState){
            setState(value)
        }

    }
    const value = object[field];
    return (
        <>
             <textarea
                 defaultValue={value}
                 className={classNames('form-control', className)}
                 name={field}
                 onChange={onChange}
                 {...props}/>
        </>

    )
}

InputText.defaultProps = defaultProps;

export default InputText;
