import React from 'react';

function scrollToFirstDangerElement() {
  const firstDangerElement = document.querySelector('.border-danger');
  if (firstDangerElement) {
    window.scrollTo({
      top: firstDangerElement.offsetTop,
      behavior: 'smooth'
    });
  }
}

export default scrollToFirstDangerElement;