import React from "react";
import {Link} from "react-router-dom";

const IssueListComponents = () => {
    return (
        <>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-danger">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Juan Dela Cruz
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <Link to="/issue/view">6: Machine is not working</Link>
                            </strong>
                        </div>
                        <small className="d-block">Equipment damage</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>

                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-danger">Critical</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-primary">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Jane Doe
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">7: POS is offline </a>
                            </strong>
                        </div>
                        <small className="d-block">IT Software</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-primary">Resolve</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-warning">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Juan Dela Cruz
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <Link to="/issue/view">6: Machine is not working</Link>
                            </strong>
                        </div>
                        <small className="d-block">Equipment damage</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-secondary">Low</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-danger">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Juan Dela Cruz
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">6: Machine is not working</a>
                            </strong>
                        </div>
                        <small className="d-block">Equipment damage</small>

                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-danger">Critical</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-primary">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Jane Doe
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">7: POS is offline </a>
                            </strong>
                        </div>
                        <small className="d-block">IT Software</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-primary">Resolve</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-primary">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Jane Doe
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">7: POS is offline </a>
                            </strong>
                        </div>
                        <small className="d-block">IT Software</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-primary">Resolve</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-primary">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Jane Doe
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">7: POS is offline </a>
                            </strong>
                        </div>
                        <small className="d-block">IT Software</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-primary">Resolve</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-primary">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Jane Doe
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">7: POS is offline </a>
                            </strong>
                        </div>
                        <small className="d-block">IT Software</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-primary">Resolve</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-primary">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Jane Doe
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">7: POS is offline </a>
                            </strong>
                        </div>
                        <small className="d-block">IT Software</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-primary">Resolve</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1 p-2 bg-white rounded shadow-sm notice notice-primary">
                <div className="d-flex text-muted pt-0">
                    <div className="pb-3 mb-0 small lh-sm w-100">
                        <div className="d-flex justify-content-between">
                            <p className="text-red-dark m-1">
                                <i className="bi bi-exclamation-triangle"></i> Jane Doe
                            </p>
                            <i className="bi bi-circle-fill text-success float-end"></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <strong className="text-red-dark m-1">
                                <a href="/view_checklist.html">7: POS is offline </a>
                            </strong>
                        </div>
                        <small className="d-block">IT Software</small>
                        <small className="d-block float-end mt-2"> May 13, 2022 </small>
                        <div className="d-flex text-muted pt-2">
                            <span className="badge bg-primary">Resolve</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IssueListComponents;
