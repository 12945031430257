import React from "react";
import Mini from "../../assets/img/mini.png";
import SidebarComponents from "../../components2/SidebarComponents";
import NavbarComponents from "../../components2/NavbarComponents";

const HomePage = () => {
    return (
        <div>
            <SidebarComponents/>
            <main className="vh-100 d-flex flex-column">
                <NavbarComponents/>
                <div className="container mt-5 pt-4">
                    <main className="container">
                        <div className="row">
                            <div className="d-flex align-items-center p-3 my-1 text-white bg-primary rounded shadow-sm">
                                <img
                                    className="me-3"
                                    src={Mini}
                                    alt=""
                                    width="38"
                                    height="28"
                                />
                                <div className="lh-1">
                                    <h1 className="h6 mb-0 text-white lh-1">
                                        Good Morning, Juan Dela Cruz
                                    </h1>
                                    <small>May 19,2022</small>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row my-1">
                                    <div className="col-md-6 mb-1 bg-white shadow-sm p-2">
                                        <div className="d-flex text-muted pt-0">
                                            <div className="pb-3 mb-0 small lh-sm w-100 p-3">
                                                <div className="d-flex justify-content-betweenp-4">
                                                    <h5>
                                                        <strong className="text-red-dark">
                                                            <i className="bi bi-folder"></i> Forms to fill (3)
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <small className="d-block float-end"> View All </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-1 bg-white shadow-sm p-2">
                                        <div className="d-flex text-muted pt-0">
                                            <div className="pb-3 mb-0 small lh-sm w-100 p-3">
                                                <div className="d-flex justify-content-betweenp-4">
                                                    <h5>
                                                        <strong className="text-red-dark">
                                                            <i className="bi bi-flag"></i> Tasks Today (3)
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <small className="d-block float-end"> View All </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-1 bg-white shadow-sm p-2">
                                        <div className="d-flex text-muted pt-0">
                                            <div className="pb-3 mb-0 small lh-sm w-100 p-3">
                                                <div className="d-flex justify-content-betweenp-4">
                                                    <h5>
                                                        <strong className="text-red-dark">
                                                            <i className="bi bi-exclamation-triangle"></i>{" "}
                                                            Open Issue (3)
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <small className="d-block float-end"> View All </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-1 bg-white shadow-sm p-2">
                                        <div className="d-flex text-muted pt-0">
                                            <div className="pb-3 mb-0 small lh-sm w-100 p-3">
                                                <div className="d-flex justify-content-betweenp-4">
                                                    <h5>
                                                        <strong className="text-red-dark">
                                                            <i className="bi bi-camera-video"></i> Courses
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <small className="d-block float-end"> View All </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 my-1 p-3 bg-white shadow-sm">
                                        <h6 className="border-bottom pb-2 mb-0">
                                            <i className="bi bi-megaphone text-dark"></i> Announcement
                                        </h6>
                                        <div className="d-flex text-muted pt-3">
                                            <svg
                                                className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                                                width="32"
                                                height="32"
                                                xmlns="http://www.w3.org/2000/svg"
                                                role="img"
                                                aria-label="Placeholder: 32x32"
                                                preserveAspectRatio="xMidYMid slice"
                                                focusable="false"
                                            >
                                                <title>Placeholder</title>
                                                <rect width="100%" height="100%" fill="#007bff"></rect>
                                                <text x="50%" y="50%" fill="#007bff" dy=".3em">
                                                    32x32
                                                </text>
                                            </svg>

                                            <p className="pb-3 mb-0 small lh-sm border-bottom">
                                                <strong className="d-block text-gray-dark">
                                                    @username
                                                </strong>
                                                Some representative placeholder content, with some
                                                information about this user. Imagine this being some
                                                sort of status update, perhaps?
                                            </p>
                                        </div>
                                        <div className="d-flex text-muted pt-3">
                                            <svg
                                                className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                                                width="32"
                                                height="32"
                                                xmlns="http://www.w3.org/2000/svg"
                                                role="img"
                                                aria-label="Placeholder: 32x32"
                                                preserveAspectRatio="xMidYMid slice"
                                                focusable="false"
                                            >
                                                <title>Placeholder</title>
                                                <rect width="100%" height="100%" fill="#e83e8c"></rect>
                                                <text x="50%" y="50%" fill="#e83e8c" dy=".3em">
                                                    32x32
                                                </text>
                                            </svg>

                                            <p className="pb-3 mb-0 small lh-sm border-bottom">
                                                <strong className="d-block text-gray-dark">
                                                    @username
                                                </strong>
                                                Some more representative placeholder content, related to
                                                this other user. Another status update, perhaps.
                                            </p>
                                        </div>
                                        <div className="d-flex text-muted pt-3">
                                            <svg
                                                className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                                                width="32"
                                                height="32"
                                                xmlns="http://www.w3.org/2000/svg"
                                                role="img"
                                                aria-label="Placeholder: 32x32"
                                                preserveAspectRatio="xMidYMid slice"
                                                focusable="false"
                                            >
                                                <title>Placeholder</title>
                                                <rect width="100%" height="100%" fill="#6f42c1"></rect>
                                                <text x="50%" y="50%" fill="#6f42c1" dy=".3em">
                                                    32x32
                                                </text>
                                            </svg>

                                            <p className="pb-3 mb-0 small lh-sm border-bottom">
                                                <strong className="d-block text-gray-dark">
                                                    @username
                                                </strong>
                                                This user also gets some representative placeholder
                                                content. Maybe they did something interesting, and you
                                                really want to highlight this in the recent updates.
                                            </p>
                                        </div>
                                        <small className="d-block text-end mt-3">
                                            <a href="#">View All</a>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </main>
        </div>
    );
};

export default HomePage;
