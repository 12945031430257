import React, { useEffect } from "react";
import { Checkbox } from "nq-component";

const choices = ["YES", "NO", "NA"];

function InputAnswerCheck({ className, object, field, setAnswer, answer, }) {

  // useEffect(() => {
  //   setAnswer('YES')
  //   object[field] = 'YES'
  // }, [])
  
  const onChange = (value) => {
    setAnswer(value);
    object[field] = value;
  };

  return (
    <div className={className}>

      
      <div className="d-flex justify-content-between w-100">
      {choices.map((c) => (
        <Checkbox
          onChange={() => onChange(c)}
          checked={answer === c}
          label={c} 
        />
      ))}
      </div>

      </div>


  );
}

export default InputAnswerCheck;
