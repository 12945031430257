import Queue from "nq";
import PubSub from "../../event/PubSub";

class SupportChatPresenter {
  constructor(view, findObjectUseCase, saveObjectUseCase, updateObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
    this.subscriber = PubSub.createSubscriber();
    this.ticket = null;
  }

  componentDidMount() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getTicket())
      .then(() => this.getTicketComments())
      .then(() => this.subscribe())
      .then(() => this.view.hideProgress())
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
    Queue.LiveQuery.on("open", () => {
      console.log("connected");
    });
    Queue.LiveQuery.on("close", () => {
      console.log("closed");
    });
  }

  componentWillUnmount() {
    this.subscriber.unsubscribe("create");
  }

  getTicket() {
    this.ticket = this.view.getTicket();
    this.view.setTicket(this.ticket);
    if (!this.ticket) {
      const id = this.view.getChatId();
      const query = {
        where: { id: id },
        include: [
          "ticket.department",
          "ticket.creator",
          "ticket.category",
          "sender",
        ],
      };
      return this.findObjectUseCase
        .execute("tickets", query)
        .then(([ticket]) => {
          this.ticket = ticket;
          this.view.setTicket(this.ticket);
        });
    }
  }

  subscribe() {
    const sender = this.view.getCurrentUser();
    const query = {
      collection: "ticket_comments",
    };
    const subscription = Queue.LiveQuery.subscribe(query);
    subscription.on("create", (comment) => {
      if (this.comment && this.comment.id === comment.id) return;
      this.comment = comment;
      if (
        comment.ticket.id === this.ticket.id &&
        comment.sender.id !== sender.id
      ) {
        const comments = this.view.getTicketComments();
        comments.push(comment);
        this.view.setTicketComments(comments);
      }
    });
  }

  showNotification(message) {
    const content = message.content;
    const title = `new message`;
    this.view.showNotification(content, title).then(() => {
      window.open(window.location.origin + "/support/chat/" + message.chat.id);
    });
  }

  getTicketComments() {
    if (this.ticket) {
      const query = {
        where: { ticket: { id: this.ticket.id } },
        include: [
          "ticket.department",
          "ticket.creator",
          "ticket.category",
          "sender",
        ],
      };
      return this.findObjectUseCase
        .execute("ticket_comments", query)
        .then((messages) => {
          this.view.setTicketComments(messages);
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  onCloseTicket() {
    const ticket = this.view.getTicket();
    this.view
      .showDialog({
        title: "Close Ticket?",
        message: "Are you sure you want to close this ticket?",
      })
      .then((res) => {
        ticket.status = "closed";

        ticket.date_closed = new Date(Date.now()).toISOString();
        this.updateObjectUseCase
          .execute("tickets", ticket)
          .then(() => {
            this.view.showSuccess("Ticket successfully closed!");
            this.view.navigateTo("/support");
          })
          .catch((error) => {
            this.view.hideProgress();
            this.view.showError(error);
          });
      });
  }

  onClickBack() {
    this.view.navigateBack();
  }

  onSubmitTicketComment() {
    const comment = this.view.getTicketComment();
    comment.ticket = this.ticket;
    this.view.setTicketComment({});
    this.onSubmitMessage();
  }

  onSubmitMessage() {
    const ticket = this.view.getTicket();
    const sender = this.view.getCurrentUser();
    const comment = this.view.getTicketComment();
    comment.chat = ticket;
    comment.sender = sender;
    this.view.setTicketComment({});
    if (this.delay) return;
    if (!ticket.id) {
      this.delay = true;
    }
    const newMessage = {
      ticket: comment.chat,
      content: comment.content,
      sender,
    };

    // // wait after chat is created
    if (this.delay) return;
    // // check if no chat is created
    if (!newMessage.ticket.id) {
      this.delay = true;
    }

    this.view.showProgress();
    this.findObjectUseCase
      .execute("tickets", { where: { id: ticket.id } })
      .then(([ticket]) => {
        if (ticket.status.toLowerCase() === "closed") {
          this.view.showError("This Ticket is Already closed");
          return;
        }
        this.saveObjectUseCase
          .execute("ticket_comments", newMessage)
          .then((comment) => {
            if (this.delay) {
              this.delay = false;
              // navigate to new message to have chat id
              this.view.navigateTo("/support/chat", comment.ticket, {
                replace: true,
              });
            }
            const comments = this.view.getTicketComments();
            comments.push(comment);
            this.view.setTicketComments(comments);
            // return this.view.setStatePromise({ticket_comments: comment.ticket});
            this.view.hideProgress();
          })
          .then(() => {
            this.delay = false;
          })
          .catch((error) => {
            this.view.hideProgress();
            this.view.showError(error);
          });
      });
  }
}

export default SupportChatPresenter;
