class DashboardPrenseter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.current = 1;
    this.where = {};
    this.documents = [];
    this.progress = false;
  }

  componentDidMount() {
    this.getSupport();
    this.getLearn();
    this.getAnnouncement();
  }

  getLearn() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      skip,
      include: ["all"],
      sort: { createdAt: -1 },
    };
    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("learns", query)
      .then(({ count, objects }) => {
        this.documents = this.documents.concat(objects);
        this.view.setCountLearn(count);
        this.view.setLearns(this.documents);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getSupport() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      skip,
      where: (this.where = {
        status: "open",
      }),

      include: ["all"],
      sort: { createdAt: -1 },
    };
    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("tickets", query)
      .then(({ count, objects }) => {
        this.documents = this.documents.concat(objects);
        this.view.setCountSupport(count);
        this.view.setSupports(this.documents);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getAnnouncement() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      limit: (this.limit = 3),
      skip,
      include: ["all"],
      sort: { createdAt: -1 },
    };
    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("announcements", query)
      .then(({ count, objects }) => {
        this.documents = this.documents.concat(objects);
        this.view.setCountAnnouncement(count);
        this.view.setAnnouncements(this.documents);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default DashboardPrenseter;
