import React from "react";
import InputFactory from "../InputFactory";

function InputImageAnswer({ object }) {
  const [images, setImages] = React.useState(object["picture"] || []);

  //   console.log("State IMG", images);

  const handleAddImage = () => {
    const newImages = images || []; ///init yung empty array kung ang images is undefined
    setImages([...newImages, ""]);
  };

  return (
    <div>
      <div className="row">
        {Array.isArray(images) &&
          images.map((image, index) => (
            <div className="col-auto m-2" key={index}>
              <InputFactory
                className=""
                type="Image"
                field="picture"
                object={object}
                index={index}
                value={image}
              />
            </div>
          ))}

        <div className="col-auto m-2 rou">
          <button
            className="btn btn-primary rounded"
            type="button"
            onClick={handleAddImage}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
}

export default InputImageAnswer;
