import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import {InputString} from "nq-component";
import Card from "../../components/Card";

class FormPage extends BasePage {

    render() {
        return (
            <>
                <NavBar className="shadow-sm"/>
                <div className="nav-scroller">
                    <nav className="nav nav-tabs">
                        <button className="text-nowrap nav-link active" href="#">
                            <i className="bi bi-bookmark-check"></i> New
                        </button>
                        <button className="text-nowrap nav-link" href="#">
                            <i className="bi bi-bookmark-check"></i> Draft
                        </button>
                        <button className="text-nowrap nav-link" href="#">
                            <i className="bi bi-bookmark-check"></i> Sent
                        </button>
                    </nav>
                </div>


                <div className="container">
                    <div className="py-3 px-lg-5 py-lg-4">
                        <Card className="mb-3">
                            <div className="input-group">
                                <InputString
                                    placeholder="Filter .."/>
                                <button
                                    className="btn btn-primary"
                                    type="button">
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </Card>
                        <Card className="mb-3">
                            <h6 className="border-bottom pb-2 mb-0">Forms to fill (3)</h6>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-red-dark">
                                            <a href="/view_checklist.html">Daily Sales Reporting </a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Accounting</small>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-gray-dark">
                                            <a href="">Municipal Requirements for Store opening</a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Accounting</small>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-gray-dark">
                                            <a href="">Sanitazion Engineering</a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Accounting</small>
                                </div>
                            </div>
                            <small className="d-block text-end mt-3">
                                <a href="#">All forms to fill</a>
                            </small>
                        </Card>
                        <Card className="mb-3">
                            <h6 className="border-bottom pb-2 mb-0">Folders</h6>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-folder me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-red-dark">Accounting </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-folder me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-red-dark">
                                            Information Technology{" "}
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-folder me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-red-dark">Audits </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                </div>
                            </div>

                            <small className="d-block text-end mt-3">
                                <a href="#">All folder</a>
                            </small>
                        </Card>
                        <Card className="mb-3">
                            <h6 className="border-bottom pb-2 mb-0">All Forms</h6>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-red-dark">
                                            <a href="">Daily Sales Reporting </a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Accounting</small>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-gray-dark">
                                            <a href="">Municipal Requirements for Store opening</a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Accounting</small>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-gray-dark">
                                            <a href="">Sanitazion Engineering</a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Operation</small>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-gray-dark">
                                            <a href="">BIR Compliance for Income Tax Return</a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Operation</small>
                                </div>
                            </div>
                            <div className="d-flex text-muted pt-3">
                                <i className="bi bi-files me-2"></i>
                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div className="d-flex justify-content-between">
                                        <strong className="text-gray-dark">
                                            <a href="">Bank Company Transfer</a>
                                        </strong>
                                        <i className="bi bi-chevron-right float-end"></i>
                                    </div>
                                    <small className="d-block">Operation</small>
                                </div>
                            </div>
                            <small className="d-block text-end mt-3">
                                <a href="#">All forms</a>
                            </small>
                        </Card>
                    </div>

                </div>
            </>
        )
    }
}

export default FormPage;
