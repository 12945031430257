class AnnouncementDetailsPresenter {
  constructor(
    view,
    findObjectUseCase,
    saveObjectUseCase,
    updateObjectUseCase,
    saveFileUseCase
  ) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
    this.saveFileUseCase = saveFileUseCase;
    this.announcement = null;
  }

  componentDidMount() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getAnnouncement())
      .then(() => this.view.hideProgress())
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getAnnouncement() {
    this.announcement = this.view.getAnnouncement();
    this.view.setAnnouncement(this.announcement);
    if (!this.announcement) {
      const id = this.view.getAnnouncementId();
      const query = {
        where: { id: id },
        include: ["all"],
      };
      return this.findObjectUseCase
        .execute("announcements", query)
        .then(([announcement]) => {
          this.announcement = announcement;
          this.view.setAnnouncement(this.announcement);
        });
    }
  }

  onClickBack() {
    this.view.navigateBack();
  }
}

export default AnnouncementDetailsPresenter;
