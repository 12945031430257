class SupportPresenter {
    constructor(view, findUseCase) {
        this.view = view;
        this.findUseCase = findUseCase;
        this.limit = 10;
        this.current = 1;
        this.where = {
            status: "open",
        };
        this.documents = [];
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const skip = (this.current - 1) * this.limit;
        const query = {
            count: true,
            limit: 20,
            skip,
            sort: {createdAt: -1},
            where: this.where,
            include: ["all"],
        };
        this.view.showProgress();
        this.findUseCase.execute("tickets", query)
            .then(({count, objects})=>{
        
                this.documents = this.documents.concat(objects);
                this.view.setCount(count);
                this.view.setTickets(this.documents)
                this.view.hideProgress();
            })
            .catch((error) => {
                this.view.hideProgress();
                this.view.showError(error);
            });
    }

    onClickOpened() {
        this.where.status = "open";
        this.documents = [];
        this.getData();
    }

    onCLickClosed() {
        this.where.status = "closed";
        this.documents = [];
        this.getData();
    }

    onClickTicket(chat) {
        this.view.navigateTo("/support/chat", chat);
    }

    loadMore() {
        if (!this.view.state.progress) {
            this.current++;
            this.getData();
        }
    }

    onSubmitSearch(word) {
        this.where.id = { $regex: word.toLowerCase() };

        this.documents = [];
        this.current = 1;
        this.getData();
    }
}

export default SupportPresenter;