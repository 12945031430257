function answersScore(answers) {
    const result = {
        score: 0,
        total: 0,
    }
    answers.forEach(answer => {
        if (answer.answer === 'YES') {
            result.score += answer.question.points;
        }
        if (answer.answer === 'YES' || answer.answer === 'NO') {
            result.total += answer.question.points;
        }
    });
    return result;
}

export default answersScore;